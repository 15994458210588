.container.contact__container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Isso permite que os itens passem para a próxima linha se não couberem */
    gap: 1.2rem;
    justify-content: center; /* Centraliza os itens horizontalmente */
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    min-width: 1/3;
}

.contact__option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* =============== FORM ==============*/
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

/* ======================= MEDIA QUERIES (MEDIUM DEVICES) =====*/
@media screen and (max-width: 1024px){
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* ======================= MEDIA QUERIES (SMALL DEVICES) =====*/
@media screen and (max-width: 600px){
    .container.contact__container{
        width: var(--container-width-sm);
    }
}
